import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {DwollaService } from './dwolla.service';
import {Person} from '@facepay/api-client/build/facepay/dao/person';
import {Status} from '@facepay/api-client/build/facepay/dao/base';
import { supportsPassiveEventListeners } from '@angular/cdk/platform';
import {Storage} from '@ionic/storage';
import {Facepay, Dictionary} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';
import {Purchase} from '@facepay/api-client/build/facepay/dao/purchase';
import {AsYouType, parsePhoneNumberFromString} from 'libphonenumber-js/min';
import {FacepayService} from './facepay.service'
import {TwilioService} from './twilio.service'
import {PlaidService} from './plaid.service'
import {ServicesService} from './services.service';
import {CurrencyPipe, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {FacepayClient}  from '@facepay/api-client';

@Injectable({
    providedIn: 'root'
})
export class UtilityService extends BaseService {
    constructor(
        public storage: Storage, 
        public httpClient: HttpClient, 
        private dwollaService: DwollaService,
        private facepayService: FacepayService,
        private twilioService: TwilioService,
        private currencyPipe: CurrencyPipe,
        private plaidService: PlaidService,
        private servicesService:ServicesService,
        private sanitizer:DomSanitizer
        ) {
        super(  storage, httpClient );
    }
    // constructor(
    //     private dwollaService: DwollaService
    // ){
        
    // }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        
        if (match) {
          return ['(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    checkIfNull( value ){
        if (!value || value === null || value === undefined || value === "false" || value === "null" || value === "undefined") {                           
            return true;
        }else{
            return false;
        }     
    }

    checkIfTrue( value ){
      if (value && (value === "true" || value === true) ){
        return true;
      }else{
        return false;
      }
    }
    checkIfFalse( value ){
      if ( ( value && value === "false" ) || value === false ){
        return true;
      }else{
        return false;
      }
    }
    getNotNullValue( value ){
        if (!value || value === null || value === undefined || value === "false" || value === "null" || value === "undefined") {                           
            return "";
        }else{
            return value;
        }     
    }

    getMonthName( month, format = "long" ){
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
        const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        if(  format === "short"){
            return monthNamesShort[ month - 1 ];
        }else{
            return monthNames[ month - 1 ];
        }
    }


    getDayName(day) {
        var j = day % 10,
            k = day % 100;
        if (j == 1 && k != 11) {
            return day + "st";
        }
        if (j == 2 && k != 12) {
            return day + "nd";
        }
        if (j == 3 && k != 13) {
            return day + "rd";
        }
        return day + "th";
    }

    validateCell( cell ){
        const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return re.test(cell);
    }

    valideCellE164( cell ){
      try {
        const PHONE_NUMBER_REGX = /^\+[1-9]\d{10,14}$/; // E.164
        let phoneUSFormatted = new AsYouType('US').input( cell )
        const phoneObj = parsePhoneNumberFromString( phoneUSFormatted, 'US')
        let phone = phoneObj.format('E.164')
        
        if( PHONE_NUMBER_REGX.test( phone ) ){
          return phone;
        }else{
          return false;
        }
      } catch (err) {
        return false;
      }
    }

    showeHtmlNewline( message ){
        if ( this.checkIfNull(message) ){
            return "";
        }else{
            return message.replace(new RegExp('\r?\n','g'), '<br />');
        }
        
    }

    showeHtmlNewlineAnddeURIComponent( message ){
        if ( this.checkIfNull(message) ){
            return "";
        }else{
            return decodeURIComponent( message ).replace(new RegExp('\r?\n','g'), '<br />');
        }        
    }

    validPersonSuspended( person: Person ){
        if (person && person.getStatus() === Status.SUSPENDED  ){                
            return true;
        }
        return false;
    }

    async checkPersonStatus( person: Person ):Promise<boolean> {
      let ifDwollaFundingSource: boolean = true;
	    let customerUnverified = null;
	    let ifCustomerUnverified: boolean = false;
	    let customerStatus = null;
	    let ifCustomerStatus: boolean = false;
	    let fundingSource = null;
	    let ifFundingSource: boolean = false;
	    let fundingStatus = null;
	    let ifFundingStatus: boolean = false;
	
	    if ( person.getOption('dwolla.customer.unverified') ){
	      customerUnverified = person.getOption('dwolla.customer.unverified');
	      if ( customerUnverified && !(customerUnverified === 'null' ) ){
	        ifCustomerUnverified = true;
	      }else{
	        ifCustomerUnverified = false;
	      }
	    }
	
      //based on mail Oct.7, remove the item. 
	    if ( person.getOption('dwolla.customer.status') ){
	      customerStatus = person.getOption('dwolla.customer.status');
	      if ( customerStatus === 'unverified' || customerStatus === 'submitted' ){
	        ifCustomerStatus = true;
	      }else{
	        ifCustomerStatus = false;
	      }
	    }
	
	    if ( person.getOption('dwolla.funding.source') ){
	      fundingSource = person.getOption('dwolla.funding.source')
	      if ( fundingSource && !(fundingSource === 'null' ) ){
	        ifFundingSource = true;
	      }else{
	        ifFundingSource = false;
	      }
	    }
	
	    if ( person.getOption('dwolla.funding.status') ){
	      fundingStatus = person.getOption('dwolla.funding.status');
        ifFundingStatus = true;
	      if ( fundingStatus === 'verified' ){
	        ifFundingStatus = true;
	      }else{
	        ifFundingStatus = false;
	      }
	    }

	    /*
	    if(ifFundingSource){
	      let customerUrl = fundingSource;
	      await this.dwollaService.describe({customerUrl: customerUrl}).then((result: any) => {
	        if ( result.status === "verified" ){
	          ifDwollaFundingSource = true;
	        }else{
	          ifDwollaFundingSource = false;
	        }
	      }).catch(function(e){
	        ifDwollaFundingSource = false;
	        console.log("===== pay  describe catch",e);
	        
	      })
	    }
	    */
	
	    // console.log( " ==== checkPersonStatus result:  customerUnverified = " + customerUnverified + " ; ifcustomerUnverified = " + ifCustomerUnverified +
	    //   " customerStatus = " + customerStatus + " ; ifcustomerStatus = " + ifCustomerStatus +
	    //   " fundingSource = " + fundingSource + " ; iffundingSource = " + ifFundingSource +
	    //   " fundingStatus = " + fundingStatus + " ; iffundingStatus = " + ifFundingStatus + " ifDwollaFundingSource = " + ifDwollaFundingSource
	    // );
	
	    if( ifCustomerUnverified && ifCustomerStatus && ifFundingSource && ifFundingStatus && ifDwollaFundingSource ){
	      return true;
	    }	
        return false;
    }

    checkPersonStatusIfNull11( person ){
      var p_options = person.getOptions();
    	      
      if(p_options && p_options['dwolla.customer.status'] && p_options['dwolla.customer.unverified'] && p_options['dwolla.funding.status'] && p_options['dwolla.funding.source']){
        return true;
       //show guest on dashboard
       
        let ifDwollaFundingSource = false;     
        let customerUnverified = null;
        let ifCustomerUnverified = false;
        let customerStatus = null;
        let ifCustomerStatus = false;
        let fundingSource = null;
        let ifFundingSource = false;
        let fundingStatus = null;
        let ifFundingStatus = false;
               
        
        customerUnverified = p_options['dwolla.customer.unverified'];
        if (customerUnverified && !(customerUnverified === 'null')) {
            ifCustomerUnverified = true;
        }else {
            ifCustomerUnverified = false;
        }
               
            
        customerStatus = p_options['dwolla.customer.status'];
        if (customerStatus === 'unverified' || customerStatus === 'submitted') {
            ifCustomerStatus = true;
        }else {
            ifCustomerStatus = false;
        }
             
        fundingSource = p_options['dwolla.funding.source'];
        if (fundingSource && !(fundingSource === 'null')) {
            ifFundingSource = true;
        }else {
            ifFundingSource = false;
        }
               
        fundingStatus = p_options['dwolla.funding.status'];
        if (fundingStatus === 'verified' || fundingStatus === 'unverified') {
            ifFundingStatus = true;
        }else {
            ifFundingStatus = false;
        }
                           
       
        
         if (ifCustomerUnverified && ifCustomerStatus && ifFundingSource && ifFundingStatus && ifDwollaFundingSource) {
             //instant ready    
         } else{
             //manual signup
         }
      }else{
        return false;
      }


    }

    checkPersonStatusIfNull( person: Person ) {
      

	    let ifCustomerUnverified = true;
	    let ifCustomerStatus;
	    let ifFundingSource;
	    let ifFundingStatus;

      let personOptions = person.getOptions();


      let optionCustomerUnverified = personOptions.find( ( option) => {
            if ( option.name === "dwolla.customer.unverified" ){
              return option;
            }                      
          })

      if ( !this.checkIfNull ( optionCustomerUnverified ) && person.getOption('dwolla.customer.unverified')){
	        ifCustomerUnverified = true;
	    }
	
      //based on mail Oct.7, remove the item. 
      let optionCustomerStatus = personOptions.find( ( option) => {
        if ( option.name === "dwolla.customer.status" ){
          return option;
        }
      })
	    if (  !this.checkIfNull (  optionCustomerStatus ) && person.getOption('dwolla.customer.status')){
	        ifCustomerStatus = true;
	    }
	
      let optionFundingSource = personOptions.find( ( option) => {
        if ( option.name === "dwolla.funding.source" ){
          return option;
        }
      })
	    if (  !this.checkIfNull ( optionFundingSource ) && person.getOption('dwolla.funding.source') ){
	        ifFundingSource = true;
	    }
	
      let optionFundingStatus = personOptions.find( ( option) => {
        if ( option.name === "dwolla.funding.status" ){
          return option;
        }
      })

	    if (  !this.checkIfNull (  optionFundingStatus )  && person.getOption('dwolla.funding.status') ){
	        ifFundingStatus = true;
	    }

	    if( ifCustomerUnverified && ifCustomerStatus && ifFundingSource && ifFundingStatus  ){
	      return true;
	    }	

      return false;

    }
    
    //person.dwollafundingsource != business.dwolla.funding.destination. It means, user pay money to theirself.  
    checkPersonFunding( person, business ){
      try{
        if ( person && person.getOption("dwolla.funding.source") && 
              business && business.getOption("dwolla.funding.destination") &&
              person.getOption("dwolla.funding.source") === business.getOption("dwolla.funding.destination")){
          return false;
        }else{
          return true;
        }
      }catch( e ){
        console.log( " ====== checkPersonFunding error " , e );
        return true;
      }      
    }

    checkPersonMicroDeposit( person ){
      console.log( " ====== checkPersonMicroDeposit person " , person )
      if ( person && person.getOption('microdepostit.attept') ){
        let microdepostitAttept = person.getOption('microdepostit.attept');
        if ( microdepostitAttept == 1 ){
          return true;
        }else{
          return false;
        }  
      }
      return false;
    }

    whatIsIt(object) {
      let stringConstructor = "test".constructor;
      let arrayConstructor = [].constructor;
      let jsonConstructor = {}.constructor;
      let objectConstructor = ({}).constructor;

      if (object === null) {
          return "null";
      }
      if (object === undefined) {
          return "undefined";
      }
      if ( typeof object === "boolean") {
        return "Boolean";
      }
      if (object.constructor === stringConstructor) {
          return "String";
      }
      if (object.constructor === arrayConstructor) {
          return "Array";
      }
      if (object.constructor === jsonConstructor) {
          return "Json";
      }
      if (object.constructor === objectConstructor) {
        return "Object";
    }
      {
          return "don't know";
      }
  }

    //According to requirement from George, the 2 purchase status = failed, need change the status as process
    //0xf17ddc5035Ff6D843Db3e642B507998622ce1827, 0xEa1307FbB257069fA1cd24e73dBDaa76089BF7D2
    //0x61697467d1156EDd2F9CC9f5FDDAdFCb6957CF34， 0x1310b220626c9baa12f696fd1713566525d20F8C

    //0xf34D6B86c26D20bCF9e96E5859D1d6dfd084f879
    changePurchaseFailedStatus( purchase, returnStatus ){
      if ( purchase.getAddress() === "0xf17ddc5035Ff6D843Db3e642B507998622ce1827" || 
        purchase.getAddress() === "0xEa1307FbB257069fA1cd24e73dBDaa76089BF7D2" ||
        purchase.getAddress() === "0x61697467d1156EDd2F9CC9f5FDDAdFCb6957CF34" || 
        purchase.getAddress() === "0x1310b220626c9baa12f696fd1713566525d20F8C" ||
        purchase.getAddress() ===  "0xf34D6B86c26D20bCF9e96E5859D1d6dfd084f879" ||
        purchase.getAddress() ===  "0xa7EE0291a49b29E33bD8E7b0a9A63A62ACa5Aa3f" ||
        purchase.getAddress() ===  "0x1cC23c69A4c13b57aDF928170765436Ed03fC19d" ||
        purchase.getAddress() ===  "0x0F67584d93506E78f6EE1Ca7BdADDa6d9475351b" ||
        purchase.getAddress() ===  "0xD7DED0e27e45D98823d277796D62C2A99818dc14" ||
        purchase.getAddress() ===  "0xc36202553579f4eE1fb77778ad1F2511C005f87B" ||
        purchase.getAddress() ===  "0x1D6E4C0dc196D9f2306b9Bdc1ab09e8382580f52" ||
        purchase.getAddress() ===  "0xFbc627edD2d47205f952E487F67edB8F75638532" 
        ){
          return "Processed";
        }else{
          // return "Under Review";
          return returnStatus;
        }
    }

    async getCellNumber( signupService ){
      let cellNo = null;
      try{
        if ( signupService.cell ){
          return signupService.cell;
        }else if ( signupService.person && signupService.person.getCell() ){
          cellNo = signupService.guest.person.getCell();
        }else if ( signupService.guest && signupService.guest.person && signupService.guest.person.getCell() ){
          cellNo = signupService.guest.person.getCell();
        }else{
          let p : Person = new Person();
          let person:any = await this.facepayService.findUser( signupService.currentUser );
          cellNo = person.getCell();
        }
      }catch( err ){

      }
      return cellNo
    }
    async getPerson( signupService ){
      let person = null;
      try{
        if ( signupService.person ){
          person = signupService.person;
        }else if ( signupService.guest && signupService.guest.person  ){
          person = signupService.guest.person;
        }else{
          let p : Person = new Person();
          person = await this.facepayService.findUser( signupService.currentUser );
        }
      }catch( err ){

      }
      return person
    }

    checkIfUserPhoneRegister( currentUser ){
      let phoneRegister = false;
      if ( currentUser.sub ){
          if ( currentUser.sub.substring(0, 3 ) === "sms" ){
            phoneRegister = true;
          }
      }
      // console.log( " ==== checkIfUserPhoneRegister result  " + phoneRegister , currentUser )
      return phoneRegister;
    }

    checkBusinessAvailable( business ){
      if ( business.getAddress() === '0x0000000000000000000000000000000000000000' || business.getAddress() === "0x0" ){
        return false;
      } else {
        return true;
      }
    }
    GetNumberFromString( str ) {
      const pattern = /[0-9.]/;
      let amount = "";
      if ( this.checkIfNull( str )){
        return "";
      }else{
        let len = str.length;
        if ( len > 0 ){          
          for( let i = 0; i < len; i ++ ){
            if ( pattern.test( str.substr(i,  1) )) {
              amount = amount + str.substr(i, 1);
            }
          }
        }
        return amount;
      }
    }
    calculateAmazonPoints( purchase: Purchase , format = 1 ){
      let amazonReward = purchase.getOption("amazon.reward");
      let pointRate = 1250;
      let points;
      if ( !this.checkIfNull(amazonReward) ){
        if ( format === 1 ){
          points = ( amazonReward * pointRate ).toLocaleString('en', {useGrouping:true})
        }else if ( format === 0 ) {
          points = ( amazonReward * pointRate );
        } else if ( format === 2 ) {
          points =  amazonReward ;
        }       
      }else{
        if ( format === 1 ){
          points = "";
        }else{
          points = 0 ;
        }    
      }       
      return points;
    }

    async clientAsync( b ){
      await this.facepayService.clientPromise().then(async (client) => {
          await client.sync(b).then(  (obj) => {
              return obj;
          })
      })
    }

    replacePurchase( business, purchaseRepl ){
      if (business.guests ) {
        let GuestCoun = business.guests.length;
        let PurchaseCoun = 0;
        
        business.guests.forEach((guest: Guest) => {

            if (guest.purchases) {

                guest.purchases.map((purchase)=>{

                    if ( purchase.getAddress() === purchaseRepl.getAddress() ){
                        return purchaseRepl;
                    }else{
                      return purchase;
                    }
                })
            }
        })
      }
    }

    getDateString( dateObj ){
      let nowDay = dateObj.getDate()  >= 10 ? dateObj.getDate() : "0" + dateObj.getDate() ;
      let nowYear = dateObj.getFullYear();
      let nowMonth = ( dateObj.getMonth() + 1 ) >= 10 ? (dateObj.getMonth() + 1 ) : "0" + (dateObj.getMonth() + 1 ); ;
      let nowDateString = nowYear + "-" +  nowMonth + "-" + ( (nowDay) + "T00:00:00Z"  );
      return nowDateString;
    }

    checkDateHowManyDays( date ) {
      if ( date.toString().length === 10 ){
        date = date * 1000;
      }
      date = Number( date );

      let today = new Date( );
      // let nowDay = today.getDate()  >= 10 ? today.getDate() : "0" + today.getDate() ;
      // let nowYear = today.getFullYear();
      // let nowMonth = ( today.getMonth() + 1 ) >= 10 ? (today.getMonth() + 1 ) : "0" + (today.getMonth() + 1 ); ;
      // let nowDateString = nowYear + "-" +  nowMonth + "-" + ( (nowDay) + "T00:00:00Z"  );
      let nowDateString = this.getDateString( today )
      let nowDate = Date.parse( nowDateString ); 

      // let today = new Date( );
      // let nowDay = today.getDate();
      // let nowYear = today.getFullYear();
      // let nowMonth = today.getMonth() + 1;
      // let nowDate = new Date( nowYear + "-" +  nowMonth + "-" + ( Number(nowDay) + " 00:00:00"  )); 

      let createDate = new Date( date );
      // let day = createDate.getDate() >= 10 ? createDate.getDate() : "0" + createDate.getDate() ;    
      // let year = createDate.getFullYear();
      // let month = (createDate.getMonth() + 1 ) >= 10 ? (createDate.getMonth() + 1 ): "0" + (createDate.getMonth() + 1 ); ;    
      // let newDateString = year + "-" +  month + "-" +  (day) + "T00:00:00Z";
      let newDateString = this.getDateString( createDate )
      let newDate = Date.parse( newDateString  ); 
      // console.log( " ==== nowDate " + nowDate + " ; nowDateString = " + nowDateString + " ; newDate " + newDate + " ; newDateString " + newDateString)
     
      const oneDay = 24 * 60 * 60 * 1000;
      let daysDifferent = (( nowDate  - newDate ) / oneDay);
      //console.log( " ==== daysDifferent: "+daysDifferent);
      return Number( daysDifferent );
  } 

    newGuestPaymentAvailable( business, guest, amount ){

      let returnStatus = true;
      
      // business.setOption("balance.check", true )
      if ( business && business.getOption("balance.check")){
        let balanceAvailable = guest.person.getOption("balances.available");
        let guestCreateDate = guest.getFieldValue("date")?guest.getFieldValue("date"):Number(0);
        let differentDays = this.checkDateHowManyDays( guestCreateDate );
        if ( differentDays < 7 && ( balanceAvailable &&  Number( balanceAvailable )  < amount
          )){
          returnStatus = false;
        }        
      }
      return returnStatus;
    }

    getHomeDefaultMessage( type ){
      let defaultMessage = {
        signupHomeMessageDefault: "Please create <span style=\"color:red\">your account at {{BusinessName}}</span>  so we can make your service as convenient as possible. We offer service updates and expedited checkout. There are no additional fees.",
        signupHomeMessageAutomotive: "We now offer you a convenient express service at {{BusinessName}}. By creating this express account now, you can have an expedited check-out and pick-up when you come get your vehicle. Please click to continue.", 
        signupHomeMessageSubscription: "Continue to see what subscription plan is most convenient for you. Please login to confirm.", 
        signupHomeMessageAmount: `You have a balance due of {{Amount}} at {{BusinessName}}. We now offer you a convenient express service. By creating this express account now and paying, you can have an expedited check-out and pick-up when you come get your vehicle. Please click to continue.`, 
        signupHomeMessageAmazon: `Thank you for your service at {{BusinessName}}. You can now redeem your Amazon reward points.`, 
        signupHomeMessageAmazonPoints: `We now make your service even more convenient here at {{BusinessName}} . By adding express options, you can get after-hours and expedited pickup when your vehicle is ready. As an additional bonus, all express customers receive instant Amazon rewards when their service is complete.`,
        signupHomeMessageDropoff:"Thank you for brining in your vehicle for service.  Please take a moment to let us know what services you would like us to perform.",
      };


      return defaultMessage[type];
    }

    getHomeCurrentMessage( type, business ){
      

      let signupHomeText; 
      let dict = business.getOption( "dict" );
      if ( !dict ){
          dict = {};
      }
      
      
      if ( business && business.getOption( "dict" ) ){
        let dict = business.getOption( "dict" );
        if ( dict && dict["businessoption"] && dict["businessoption"]["signup.home.text"]){
          signupHomeText = dict["businessoption"]["signup.home.text"];
        }
        
      }

      // if ( dict && dict["businessoption"] && dict["businessoption"][type] ){
      if ( signupHomeText ){
        return signupHomeText
      }else{
        return this.getHomeDefaultMessage( type );
      }
    }

    async checkIfBalanceInsufficient( business, guest, amount ){
      try{
        let checkInsufficient = business.getOption( "insufficient.balance.check" ) ;
        let days = this.checkDateHowManyDays( guest.getFieldValue("date") );
        // days = 4;
        if ( !this.checkIfNull( checkInsufficient ) && Number( amount ) > 1000 && Number( days ) > 3 ){
          let balanceAvai = await this.plaidService.balanceCheck( guest );
          if ( Number( balanceAvai ) < amount ){
            return true;
          }else{
            return false;
          }          
        }else{
          return false;
        }
      }catch( err ){
        console.log( "  checkIfBalanceInsufficient err ", err )
        return false;
      }
    }

    dict(business, term) {
      if ( business && business.getOption('industry')) {


          if (Dictionary[business.getOption('industry')] && Dictionary[business.getOption('industry')][term]) {
              return Dictionary[business.getOption('industry')][term]
          } else {
              return Dictionary['default'][term]
          }
      } else {
          return Dictionary['default'][term]
      }
    }

    getDefaultMinimumBalance( business  ){
      let minimumRequired = business.getOption("balance.minimumRequired") ? Number(business.getOption("balance.minimumRequired")) : this.dict(business, "balance.minimumRequired")
      minimumRequired = Number( minimumRequired / 100 )
      return minimumRequired;
    }

    async caclulateRemainderMaxAmount( balanceAvai, recursive_times=0){
      // let remainderSet = 500;
      // if ( Facepay.paymentLimitation ){
      //   remainderSet =  Facepay.paymentLimitation;
      // }
      // let remainder = ( Number( balanceAvai ) % remainderSet )
      // let ret = Number( balanceAvai ) - remainder - remainderSet * recursive_times;
      // if(ret > 0)
      //   return ret;
      // else
      //   return true;
      let remainderSet = 500;
      // if ( Facepay.paymentLimitation ){
      //   remainderSet = Facepay.paymentLimitation;
      // }
      let remainder = ( Number( balanceAvai ) % remainderSet )
      // console.log("caclulateNewAmount balanceAvai: "+  balanceAvai + " remainder " + remainder );
      let ret = Number( balanceAvai ) - remainder - remainderSet * recursive_times;
      // console.log("caclulateNewAmount ret: "+Number( ret ));
      if(ret > 0)
        return ret;
      else
        return false;
     }
     
     async caclulateRemainderAmount( balanceAvai,  amount, plusAmount, reminderLmit = 500,  ){

      let newAmount = amount;
      //let reminderLmit = 250;
      // if ( Facepay.paymentReminder ){
      //   reminderLmit = Facepay.paymentReminder;
      // }
      if ( !plusAmount ){
        reminderLmit = 0;
      }
      // console.log("amount value: "+amount);
      // console.log("test value: "+Number( amount +reminderLmit));
      if( Number( balanceAvai ) < Number( amount ) + reminderLmit){
        let i = 0;
        // console.log("newAmount: "+ newAmount );       
        while ( balanceAvai < Number(newAmount) + reminderLmit && newAmount > 0 ){
          let newAmountRet= await this.caclulateRemainderMaxAmount(balanceAvai,i);
          // console.log("newAmountRet: "+ newAmountRet );       
          newAmount = Number( newAmountRet );
          i++;
        }
      }
      // console.log("final  newAmount: "+ newAmount );     
      return newAmount;
     }

    async validateParameters( business, guest, amount, source, ignorePersonStatusCheck = false ){
      if ( guest ){
        let ifBusiness = guest.person.getOption( "business");
        // console.log( " ===== guest business " + ifBusiness , guest )
        if ( this.checkIfTrue( ifBusiness ) ){
          return false;
        }
      }

      if ( !ignorePersonStatusCheck ){
        let personStatus = await this.checkPersonStatus( guest.person );
        if ( !personStatus ){
          return -2;
        }  
      }

      let balanceAvai = -1;
      //let remainderSet =  this.getDefaultMinimumBalance( business, amount ); // 500;
      let minimumBalance = this.getDefaultMinimumBalance( business );
      // minimumBalance = 250;
      let remainderSet = Number( minimumBalance ) + Number( amount );
      // console.log( " ===== validateParameters guest " , guest )

      console.log( " ===== validateParameters remainderSet " + remainderSet + " ; minimumBalance " + minimumBalance )

      try{
        let checkInsufficient = business.getOption( "insufficient.balance.check" ) ;
        let personBankAvai = guest.person.getOption( "balances.available" );
        // console.log( " ===== validateParameters insufficient.balance.check " + checkInsufficient )        
        if ( this.checkIfTrue( checkInsufficient )  && !this.checkIfNull( personBankAvai ) ){

          let personBalance = guest.person.getOption( "balances.available" );
          if ( Number( personBalance ) >= Number( remainderSet ) ){
            return false;
          }else{
             let date = ( new Date()).getTime();
            if (  guest.getFieldValue( "date" ) ){
              date = guest.getFieldValue( "date" );
            }
            let days = this.checkDateHowManyDays( date );
            console.log( " ===== validateParameters days " + days )
            if ( days <= 1 ){
              this.servicesService.count('balance.insufficient', guest.getAddress(), 
                {
                  person: guest.person.getAddress(), 
                  source: source, 
                  balance: personBalance, 
                  payment: amount
                });
                console.log( " ===== validateParameters personBalance " + personBalance + "  minimumBalance = " + minimumBalance )
                if ( personBalance >= minimumBalance ){
                  let reminderAmount = await this.caclulateRemainderAmount( personBalance, amount, true, minimumBalance  )
                  // console.log( " ===== validateParameters reminderAmount " + reminderAmount )
                  if ( reminderAmount && Number( reminderAmount ) > 0 ){
                    // let remainder = (  Number( personBalance ) % remainderSet ) 
                    // return Number( personBalance ) - remainder;  
                    return reminderAmount;
                  }else{
                    return true;
                  }
                }else{
                  return true;
                }                
            }
          }
          
          //for existing user only amount will be compared here according to old logic:
          if ( Number( personBalance ) >= Number( amount ) )
            return false;
            
          //update the new balance to person options
          balanceAvai = await this.plaidService.balanceCheck( guest );
          if ( Number( balanceAvai ) >= 0 ) {              
            let checkResult = Number( balanceAvai ) < Number( amount );
            if ( checkResult ){
              this.servicesService.count('balance.insufficient', guest.getAddress(), 
                {
                  person: guest.person.getAddress(), 
                  source: source, 
                  balance: personBalance, 
                  payment: amount
                })
                //for existing customer, compare with amount only 
                let reminderAmount = await this.caclulateRemainderAmount( balanceAvai, amount, false, 0  )
                if ( reminderAmount && Number( reminderAmount ) > 0 ){
                  // let remainder = (  Number( balanceAvai ) % remainderSet ) 
                  // return Number( balanceAvai ) - remainder;
                  return reminderAmount;

                }else{
                  return true;
                }
            }else{              
              return false;
            }    
          }else{
            if ( Number( balanceAvai ) === -1 ){
              return false;
            }else{
              return true;
            }
          }
        }else{
          return false;
        }  
      }catch( err ){
        return false;
      }finally{
        if (  balanceAvai > 0 ){
          //update person             
          let person = new Person( {address: guest.person.getAddress() } ) ;
          const facepayServiceClient = await this.facepayService.clientPromise();
          person.setOption( "balances.available", balanceAvai )
          facepayServiceClient.sync(person).then((response) => {    
            console.log( " ===== update person balace " , response )          
          }).catch((err) => {              
          }).finally(
          );            
        }
      }
    }

    checkApprovalRequired( business ){
      let approvalRequired = business.getOption( "approval.required" )
      if ( this.checkIfNull( approvalRequired ) ){
        approvalRequired = 10000  + 0.0001 ;
      }
      approvalRequired = Number( approvalRequired ) - 0.0001;


      return approvalRequired;
      //  return 20000;
    }

    async upsell(guest:Guest) { 
      const upsellThreshold = 350
      let balance = guest.person.getOption("balances.available");
      let personStatus = await this.checkPersonStatus( guest.person );
      
      if (  !personStatus ||  !balance ) return "null";
      if (balance < 2 * upsellThreshold) return 'Unlikely';
      if (balance >= upsellThreshold && balance < 4 * upsellThreshold) return 'Likely';
      if (balance >= 4 * upsellThreshold) return 'Highly Likely';
    }

    getAdvisorShareNames( business ){
      let advisorNames = business.getOption("advisor.names");
      let serviceadvisorEnable = business.getOption("serviceadvisor.enable"); 
      if ( !this.checkIfNull( serviceadvisorEnable ) ){
        if ( !this.checkIfNull( advisorNames ) ){
          let namesArray = advisorNames.split( "," );
          let returnArr = [];
          if ( namesArray.length > 0 ){
            for( let name of namesArray ){
              let nameStr = name.toString().trim();
              let nameObj = { id: nameStr, value: nameStr };
              returnArr.push( nameObj );
            }
            return returnArr;
          }          
          return null;
        }else{
          return null;
        }
      }else{
        return null;
      }

    }

    getVerifiedAdvisorName( businessAdvisorNames, advisorName ){
      
    }

    findPersonByName(guestList, name): any {

                    
      if (!guestList) return null;
      if (!name) return null;

      let nameJson = this.splitName( name );

      let firstName;
      let lastName;
      if ( nameJson ){
        firstName = nameJson["first"]?nameJson["first"]:"";
        lastName = nameJson["last"]?nameJson["last"]:undefined;
      }



      let filtered = guestList.filter((item: Guest) => {
              if (item.getOption('invitation') === 'true') {
                  return false;
              } else if (!item.getPerson()) {
                  return false;
              } else if (item.getStatus() !== Status.ACTIVE) {
                  return false;
              } else {
                  let result;

                  if (item.getPerson().getFirstName() && item.getPerson().getLastName() && item.getPerson().getCell()) {
                    result =  (item.getPerson().getFirstName().toLowerCase().indexOf(firstName.toLowerCase()) > -1 ||
                              item.getPerson().getLastName().toLowerCase().indexOf(firstName.toLowerCase()) > -1 ||
                              item.getPerson().getCell().toLowerCase().indexOf(firstName.toLowerCase()) > -1)
                        
                      // console.log(  " ======= guest searchTerm.toLowerCase " , searchTerm.toLowerCase()  + " ; result " + result, item )
                      // return result;
                  }
                  if ( !result ){
                    if (item.getPerson().getFirstName() && item.getPerson().getLastName() ) {
                      result =  (item.getPerson().getFirstName().toLowerCase().indexOf(firstName.toLowerCase()) > -1 ||
                                item.getPerson().getLastName().toLowerCase().indexOf(firstName.toLowerCase()) > -1 )                        
                        // console.log(  " ======= guest searchTerm.toLowerCase " , searchTerm.toLowerCase()  + " ; result " + result, item )
                        // return result;
                    }
                  }

                  if ( !result ){
                    if (item.getPerson().getLastName()) {
                      result =  (item.getPerson().getLastName().toLowerCase().indexOf(firstName.toLowerCase()) > -1)
                    }
                  }

                  if ( !result ){
                    if (item.getPerson().getFirstName()) {
                      result =  (item.getPerson().getFirstName().toLowerCase().indexOf(firstName.toLowerCase()) > -1)
                    }
                  }
                  if ( !result ){
                    if (item.getPerson().getCell()) {
                      result =   (item.getPerson().getCell().toLowerCase().indexOf(firstName.toLowerCase()) > -1)
                    }
                  }
                  return result;
              }
      });

       if ( lastName && filtered && Array.isArray( filtered ) && filtered.length > 1){
        filtered = filtered.filter((item: Guest) => {
                if (item.getOption('invitation') === 'true') {
                    return false;
                } else if (!item.getPerson()) {
                    return false;
                } else if (item.getStatus() !== Status.ACTIVE) {
                    return false;
                } else {
                    if (item.getPerson().getLastName() ) {

                      let result = item.getPerson().getLastName().toLowerCase().indexOf(lastName.trim().toLowerCase()) > -1 
                      return result;
                    } 
                }
        });
        // return filterSecond;
      }else{
        // return filtered;
      }

      console.log( " ===== findPersonByName nameJson 2222", nameJson, filtered )

      return  filtered.sort( ( guest1, guest2 ) =>{
        if ( guest1.getPerson().getFirstName().toLowerCase() > guest2.getPerson().getFirstName().toLowerCase() ){
          return 1;
        }else if ( guest1.getPerson().getFirstName().toLowerCase() < guest2.getPerson().getFirstName().toLowerCase()  ) {
          return -1;
        }else{
          if ( guest1.getPerson().getLastName().toLowerCase() >= guest2.getPerson().getLastName().toLowerCase() ){
            return 1;
          }else if ( guest1.getPerson().getLastName().toLowerCase() < guest2.getPerson().getLastName().toLowerCase()  ) {
            return -1;
          }
        }
      })

    }

    splitName( name ){
      let returnJson = {}
      if ( name && !this.checkIfNull( name )){
        const nameRegx = /[a-zA-Z,@'0-9\.]+/g;
        let result = name.match(nameRegx);
        if ( result && Array.isArray( result ) && result.length > 0 ){
          if ( result.length === 1 ){
            returnJson["first"] = result[0]
            returnJson["last"] = undefined;
          }else{
            let finalLen = (result.length - 1);
            let lastName = result[finalLen]
            returnJson["last"] = result[ (result.length - 1) ];
            returnJson["first"] = (name.substring( 0, (name.length - lastName.length )  )).trim();
  
          }
        }
        
      }

      return returnJson;
    }
    
    validateEmail(email) {

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    getCorrectEmail( email ){
      if ( this.checkIfNull( email ) ){
        return "";
      }else{
        return email.replace(/\+\S*@/,  "@");
      }      
    }

    getCorrectCellFormat( cell ){
      if ( this.checkIfNull( cell ) ){
        return "";
      }else{
        const PhoneNumber = parsePhoneNumberFromString( cell , 'US')
        return PhoneNumber.nationalNumber;
      }      
    }

    validateLetter(inputtxt)
    {

      if ( this.checkIfNull( inputtxt ) ){
        return false;
      }
     var letters = /^[A-Za-z ,@'\.]+$/;
     if(inputtxt.match(letters))
       {
          return true;
       }
     else
       {
          return false;
       }
    }

    findAdvisorNameBaseonBusiness( businessAdvisorNames,  advisorName ){
      let returnAdvisor 
      if ( businessAdvisorNames && Array.isArray(businessAdvisorNames) && advisorName ){
        returnAdvisor = businessAdvisorNames.find( ( item) =>{
            if ( item.value.trim() === advisorName.toString().trim() ){
              return item;        
            }
        })
      }
      if ( returnAdvisor ){
        return returnAdvisor.value.trim()
      }else{
        return "";
      }
    }
    // sendErrorEmailExcel( business, guest, purchase, errorMsg , page ){
    //   try{


    //     const Excel = require('exceljs');
        
    //     const workbook = new Excel.Workbook();
    //     var worksheet = workbook.addWorksheet('Facepay'); //Add date
    //     worksheet.addRow(['Facepay']);
    //     worksheet.addRow(['Cancel payment error report: ' + moment(new Date()).format('MM-DD-YYYY h:mm:ss a')]);

    //     worksheet.addRow();
    //     worksheet.addRow([business.getName()]);
    //     worksheet.addRow();
      
    //     let data = [
    //       `guest address: ${guest.getAddress()}  `,
          
    //     ];
        
    //     worksheet.addRow( data );
    //     data = [
    //     `purchase address: ${purchase.getAddress()}  `,
    //     ]
    //     worksheet.addRow( data );

    //     data = [
    //       `errorMsg: ${errorMsg}  `,
    //     ]
    //     worksheet.addRow( data );
    //     workbook.xlsx.writeBuffer()
    //       .then((buffer) => {
    //       const email = {
    //         to: "error@facepay.io",
    //         subject: " Page: " + page + " " + `${business.getName()} address: ${business.getAddress()}, guest address: ${guest.getAddress()}, purchase address: ${purchase.getAddress()} cancel report error`,
    //         msg: 'This is cancel payment error message!',
    //         attachment: {
    //             content: buffer.toString('base64'),
    //             filename: 'cancelpayment.xlsx',
    //             type: 'application/vnd.ms-excel',
    //         }
    //       }

    //       this.twilioService.email(email).then((result) => {
    //           console.log('Facepay Reconciliation Report sent to your email. Thank you!')
    //       })
    //     }


    //     )

    //   //   const email = {
    //   //     to: "545424495@qq.com",//'error@facepay.io',
    //   //     subject: " Page: " + page + " " + `${business.getName()} address: ${business.getAddress()}, guest address: ${guest.getAddress()}, purchase address: ${purchase.getAddress()} cancel report error`,
    //   //     msg: "Please check file attached. ", //errorMsg,

    //   //   }
    //   //   this.twilioService.email(email) 

    //   }catch( err ){
    //     console.log( " ====== sendErrorEmail err ", err  )
    //   }
      
    // }
    sendErrorEmail( business, guest, purchase, errorMsg , page ){
      try{

        let csv = "";
        
        // const Excel = require('exceljs');
        
        // const workbook = new Excel.Workbook();
        // var worksheet = workbook.addWorksheet('Facepay'); //Add date

        csv = csv + "Facepay" + "\r\n";
        csv = csv + 'Cancel payment error report: ' + moment(new Date()).format('MM-DD-YYYY h:mm:ss a')  + "\r\n";
        // worksheet.addRow(['Facepay']);
        // worksheet.addRow(['Cancel payment error report: ' + moment(new Date()).format('MM-DD-YYYY h:mm:ss a')]);
        csv = csv +  "\r\n";
        csv = csv + business.getName() + "\r\n";
        csv = csv +  "\r\n";
        csv = csv + `guest address: ${guest.getAddress()}  ` + "\r\n";
        // worksheet.addRow();
        // worksheet.addRow([business.getName()]);
        // worksheet.addRow();
      
        // let data = [
        //   `guest address: ${guest.getAddress()}  `,
          
        // ];
        
        // worksheet.addRow( data );
        csv = csv + `purchase address: ${purchase.getAddress()}  ` + "\r\n";

        // data = [
        // `purchase address: ${purchase.getAddress()}  `,
        // ]
        // worksheet.addRow( data );
        // csv = csv + `errorMsg: ${ JSON.stringify( errorMsg ) .toString()}  ` + "\r\n";
        // console.log( " ====== csv ", csv )
        // data = [
        //   `errorMsg: ${errorMsg}  `,
        // ]
        // worksheet.addRow( data );
        //  mystring.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
        let leftSign = 0;
        let err2 = errorMsg.replaceAll( /[{},]/g, function( word){        
          console.log( " replace word  " + word )
          if ( word === "{" ){
            leftSign = leftSign + 1;
            return "{\r\n\t";
          }else if (  word === "}" ){
            let leftStr = "\t".repeat( leftSign );
            leftSign --;
            return leftStr +  "}\r\n\t";
          }else{
            return ",\r\n";
          }
          // if ( word === "{"){
          //   leftSign ++;
          // }else if (  )

          
        } )
        csv = csv + err2 //  (/,/g, ",\r\n");
        console.log( " ====== csv ", csv )
        const email = {
          to: 'error@facepay.io',
          subject: " Page: " + page + " " + `${business.getName()} address: ${business.getAddress()}, guest address: ${guest.getAddress()}, purchase address: ${purchase.getAddress()} cancel report error`,
          // msg: csv,
          
          msg: "This is cancel payment error message!",
          attachment: {
              content: btoa( csv ),
              filename: 'cancelpayment.csv',
              type: 'text/csv',
          }
        }
        // const email = {
        //   to: 'error@facepay.io',
        //   subject: subject,
        //   msg: parameters + "\r\n" +  errorMsg,
        // }

        this.twilioService.email(email).then((result) => {
            console.log('Facepay Reconciliation Report sent to your email. Thank you!')
        })

        // workbook.xlsx.writeBuffer()
        //   .then((buffer) => {
        //   const email = {
        //     to: "error@facepay.io",
        //     subject: " Page: " + page + " " + `${business.getName()} address: ${business.getAddress()}, guest address: ${guest.getAddress()}, purchase address: ${purchase.getAddress()} cancel report error`,
        //     msg: 'This is cancel payment error message!',
        //     attachment: {
        //         content: buffer.toString('base64'),
        //         filename: 'cancelpayment.xlsx',
        //         type: 'application/vnd.ms-excel',
        //     }
        //   }

        //   this.twilioService.email(email).then((result) => {
        //       console.log('Facepay Reconciliation Report sent to your email. Thank you!')
        //   })
        // }


        // )

      //   const email = {
      //     to: "545424495@qq.com",//'error@facepay.io',
      //     subject: " Page: " + page + " " + `${business.getName()} address: ${business.getAddress()}, guest address: ${guest.getAddress()}, purchase address: ${purchase.getAddress()} cancel report error`,
      //     msg: "Please check file attached. ", //errorMsg,

      //   }
      //   this.twilioService.email(email) 

      }catch( err ){
        console.log( " ====== sendErrorEmail err ", err  )
      }
      
    }
    sendGuestCreateErrorEmail( business, identity, errorMsg , signupService, page ){
      console.log( " ====== sendGuestCreateErrorEmail start " , business, identity, errorMsg );

      try{

        let subject = `page:${page}  user info: name: ${identity.first} ${identity.last} cell: ${identity.cell} email: ${identity.email} create guest error `;
        
        let parameters = `


        creating guest parameters:
        business address: ${business.address}\r\n
        user info:
          name: ${identity.first} ${identity.last}
          cell: ${identity.cell}
          email: ${identity.email}

        amount=${signupService.amount}\r\n
        note=${signupService.note}\r\n
        ref=${signupService.ref} \r\n
        retUrl=${signupService.retUrl}\r\n
        cbUrl=${signupService.cbUrl}\r\n
        data=${signupService.data}\r\n
        xurl=${signupService.xurl}\r\n
        updatebank=${signupService.updatebank}\r\n
        dropoff=${signupService.dropoff}\r\n
        pickupcode=${signupService.pickupcode}\r\n
        highlights=${signupService.highlights}\r\n
        subscription=${signupService.subscription}\r\n
        amazon=${signupService.amazon}\r\n
        redeem=${signupService.redeem}\r\n
        
        `


 
        const email = {
          to: 'error@facepay.io',
          subject: subject,
          msg: parameters + "\r\n" +  errorMsg,
        }

        // const email = {
        //   to: 'luckycliu@gmail.com',
        //   subject: subject,
        //   msg: parameters + "\r\n" +  errorMsg,
        // }
        this.twilioService.email(email) 


        // const email2 = {
        //   to: 'georgeanyu@hotmail.com',
        //   subject: subject,
        //   msg: errorMsg,
        // }
        // this.twilioService.email(email2) 
        
      }catch( err ){
        console.log( " ====== sendErrorEmail err ", err  )
      }
      
    }
    getBusinessOptionValueTrueFalse(  business, optionName ){
      
      
      let optioniValue = business.getOption( optionName );
      if ( ( optioniValue === undefined ) || ( typeof optioniValue !== undefined && optioniValue === false ) || this.checkIfNull( optioniValue ) ) {
          return false;
      }else{
          return true;
      }

    }

    getBusinessDictOption( business, optionName, defaultVal ){
      let dictOption = business.getOption("dict");
      let optionVal = defaultVal; 
      
      console.log( " ===== getBusinessDictOption dictOption ", dictOption )


      if( dictOption && dictOption["businessoption"] && dictOption["businessoption"][optionName] ){
        optionVal = dictOption["businessoption"][optionName];
      }

      return optionVal;
      
    }

    async  refreshCancel( guest, purchase, business ){
      let loop = true;
      let loopTime = 0;
      const _client = await this.facepayService.clientPromise();
      let latestSign = false;
      let guestNew ;
      // console.log( " ===== refresh refreshCancel " + loopTime + " guest ", guest, " purchas e",  purchase )

      while ( loop && !latestSign ){

          let latestGuest = await _client.loadAny( new Guest({address: guest.getAddress() }) ) as Guest;

          console.log( " ===== refresh time " + loopTime + " guest ", latestGuest, " purchase ", purchase )

          latestGuest.purchases.find( ( item ) =>{
              if ( item.address === purchase.address ){
                  if ( item.getOption("dwolla.transfer.status") !== purchase.getOption("dwolla.transfer.status") ){
                      latestSign = true;
                      guestNew = latestGuest;
                  }
              }
          } )
          
          loopTime ++;
          if ( loopTime >= 10 ){
              loop = false;
          }
      }
      if ( guestNew ){
          guest = guestNew;
          await this.setLatestGuestIntoBusiness( guest, business )
      }

      console.log( " ===== refreshCancel this.guest ", guest, " business ", business )          
      return guest
    }

  async refreshCheckoutGuest( guest, business ){
    let purchasesNum = guest.purchases.length;
    let currentPurasesNum = 0;
    let coun = 0;
    let guestNew;

    while( ( currentPurasesNum <= purchasesNum  ) && coun <= 10){
        const _client = await this.facepayService.clientPromise();
        guestNew = await _client.loadAny(new Guest({address: guest.address })) as Guest;
        
        currentPurasesNum = guest.purchases.length;

        coun ++;
        console.log( "====== get latest guest 111 coun " + coun, guest )
    }


    if ( ( currentPurasesNum > purchasesNum  ) ){
        guest = guestNew;
        this.setLatestGuestIntoBusiness( guest, business );
        console.log( "====== get latest guest 2222 ", guest, business )         
    }

  }

  async setLatestGuestIntoBusiness( guest, business ){
      let guests = business.guests.map( (guestItem) => {
          if ( guest.address === guestItem.address ){
              return guest;
          }else{
              return guestItem;
          }
      });
      business.guests = guests;        
  }

  getTippingSetting( business ){
    let istipping = business.getOption( "tipping" );
    if ( this.checkIfTrue( istipping )  ){
      return true;
    }else{
      return false;
    }    
  }

  getTippingPercentage( business ){
    let tipPercentage = business.getOption( "tip.percentage" );
    if (  !this.checkIfNull( tipPercentage ) &&  !Number.isNaN( Number( tipPercentage ) ) && Number( tipPercentage ) > 0  ){
      return  Number( tipPercentage );
    }else{
      return 0;
    }
  }
  
  getTippingAmount( business ){
    let tipAmount = business.getOption( "tip.amount" );
    if (  !this.checkIfNull( tipAmount ) &&  !Number.isNaN( Number( tipAmount ) ) && Number( tipAmount ) > 0 ){
      return  Number( tipAmount );
    }else{
      return 0;
    }
  }

  tippingShowModeEnable( business, amount  ){
    let tipping = this.getTippingSetting( business );
    if ( tipping && amount > 0 ){
      return true;
    }else{
      return false;
    }
  }

  businessCondenseFalse( business ){

    if (  this.checkIfFalse( business.getOption( "signup.home.logo.condense" ) ) ){
      return true;
    }else{
      return false;
    }
  }

  getCorrectDateTime( date ){
    if ( this.checkIfNull( date ) ){
      return 0;
    }else{
      if ( date.toString().length === 10 ){
        return date * 1000;
      }else{
        return date;
      }
    }
  }

  sortBy(array, comparer) {
    
    // let { length } = array
    let localArray = [];
    
    Object.assign( localArray, array )
    console.log( " sortBy localArray ", localArray )

    if ( (typeof comparer) === "function" ){
      array.sort(comparer)
    }else{
      array.sort(function( a, b){
        if ( Array.isArray( comparer )){
          if ( a[comparer[0]] >= b[comparer[0]] ){
            return 1;
          }else{
            return -1;
          }
        }        
      })
    }
    console.log( " sortBy array ", array )
    return array
  }

  async checkDuplicate(guest, amount, guestRefreshTime) {
    const now = (new Date()).getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    let previous2daysNum = now - ( oneDay * 2 ) ;
    let previous2daysDateString = this.getDateString( ( new Date( previous2daysNum ) ) )
    let previous2days = Date.parse( previous2daysDateString );
    

    let isDuplicate: boolean = false;
    const purchases = guest.getPurchases();
    let purchaseArray = [];
    for ( let purchase of purchases) {
      if ( !this.isAvailablePurchase( purchase ) ){
        continue;
      }
      let purchaseTime = Number( purchase.getDate() );
      
      if (  purchaseTime >= previous2days &&  purchaseTime <= now ){
        if (purchase.getAmount() === amount ) {
          isDuplicate = true;                   
        }
        purchaseArray.push( purchase.getAmount() )
      }        
    }

    // let returnObj = {
    //   duplicate: isDuplicate
    // }
    let returnMsg;

    if ( purchaseArray.length > 0 ){      
      let paymentMessage = this.paymentListMessage( purchaseArray )
      let message = ""; 
      if ( isDuplicate ){
        if ( purchaseArray.length == 1 ){
          message = `We found you made a payment of ${paymentMessage} recently.  Do you really want to submit a duplicate payment?`
        }else{
          message = `We found you made payments of ${paymentMessage} recently.  Do you really want to submit a duplicate payment?`
        }
      }else{
        if ( purchaseArray.length == 1 ){
          message = `We found you made a payment of ${paymentMessage} recently.  Do you really want to submit another payment?`
        }else {
          message = `We found you made payments of ${paymentMessage} recently.  Do you really want to submit another payment?`
        }
      }
      console.log( " ==== purchaseArray ", purchaseArray, " message " + message )
      returnMsg = message
    }
    console.log( " ==== purchaseArray returnObj ", returnMsg , guest  )
    return returnMsg;
  }

  paymentListMessage( purchaseArray ){
    if ( purchaseArray.length === 1 ){
      return this.currencyPipe.transform(purchaseArray[0], 'USD', 'symbol', '1.2-2' )
    }else{
      let returnAmountText = "";
      for ( let i = 0; i < (purchaseArray.length -1); i ++   ){
        if ( returnAmountText === ""){
          returnAmountText = this.currencyPipe.transform(purchaseArray[i], 'USD', 'symbol', '1.2-2' ) 
        }else{
          returnAmountText = returnAmountText + ", " + this.currencyPipe.transform(purchaseArray[i], 'USD', 'symbol', '1.2-2' )
        }        
      }
      returnAmountText = returnAmountText + " and " + this.currencyPipe.transform(purchaseArray[purchaseArray.length -1], 'USD', 'symbol', '1.2-2' )
      return returnAmountText;
    }

  }

  async reloadGuest( guest ) {

    const _client = await this.facepayService.clientPromise();
    const result: Guest = await _client.loadAny(new Guest({address: guest.getAddress()})) as Guest;

    return result;

  }
  async checkDuplicate11(guest, amount,  guestRefreshTime ) {
    const now = moment();
    let latestGuest = guest;
    const client = await new FacepayClient(Facepay.api_direct);
    let lastModifiedDate = await client.getLastModifiedDate( guest );
    // let lastModifiedDate = 0;
    console.log( " checkDuplicate guestRefreshTime " + guestRefreshTime , lastModifiedDate )
    let ignoreReload = false;
    if ( lastModifiedDate  ){
        lastModifiedDate = Number( lastModifiedDate );
        if ( lastModifiedDate.toString().trim().length === 10 ){
            lastModifiedDate = lastModifiedDate * 1000;
        }
        if ( lastModifiedDate < guestRefreshTime ){
            ignoreReload = true;
        }
    }

    if ( !ignoreReload ){
        latestGuest = await this.reloadGuest( guest )
        console.log( " ==== checkDuplicate latest Guest  ", latestGuest )
    }

    
    let isDuplicate: boolean = false;

    if ( latestGuest ){            
        const purchases = latestGuest.purchases;
    
        for ( let purchase of purchases) {
            if (purchase.getAmount() === amount && now.format('L') === moment(purchase.getDate()).format('L') ) {
                isDuplicate = true;
                break;
            }
        }
    }
    return isDuplicate;    

  }

  getCustomerFacepay( guest ){
    if ( guest && guest.person.getOption('dwolla.customer.facepay')=== 'verified' ){
      
      return {
        customerFacepay: true,
        chargeLimit: 20000,
        maxAmountPerTime: 10000,
        
      };
    }else{
      return {
        customerFacepay: false,
        chargeLimit: 10000,
        maxAmountPerTime: 5000,
      };
    }
  }

  async checkWeeklyExceeded(guest, amount, chargeLimit) {

    const now = (new Date()).getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    let previous7daysNum = now - ( oneDay * 7 ) ;
    let previous7daysDateString = this.getDateString( ( new Date( previous7daysNum ) ) )
    let previous7days = Date.parse( previous7daysDateString );

    let isExceeded: boolean = false;


    const purchases = guest.getPurchases();

    let previousAmount: number = 0;


    for ( let purchase of purchases) {

      if ( Number(purchase.getDate()) > previous7days && this.isAvailablePurchase( purchase ) ){
        previousAmount += purchase.getAmount()
      }        
    }

    return  ( previousAmount + amount) > ( chargeLimit );

  }

  isAvailablePurchase( purchase ){
    if ( purchase && purchase.getOption('dwolla.transfer.status') !== "cancelled" ){
      return true;
    }else{
      return false;
    }

  }

  getUpsellImg( upsell ){
    let img = "";
    if ( upsell === "Highly Likely" ){
        img =  "/assets/img/upsell/Heath_High.png";
    }else if ( upsell === "Likely" ){
        img = "/assets/img/upsell/Heath_Medium.png";
    }else if ( upsell === "Unlikely" ){
        img = "/assets/img/upsell/Heath_Low.png";
    }else{
        img = "/assets/img/upsell/Heath_None-Disab.png";
    }
    console.log( " ====== getUpsellImg upsell " + upsell + " img = " + img );
    return img;
}

getUpsellNotice( upsell ){
    let html = "";
    if ( upsell === "Highly Likely" ){
        html = (`This customer is highly likely to purchase your recommended services and better options. You should present finance options along with your recommendations.`);
    }else if ( upsell === "Likely" ){
        html = (`This customer is likely to purchase your recommended services and better options. You should present finance options along with your recommendations.`);
    }else if ( upsell === "Unlikely" ){
        html = (`This customer is unlikely to purchase your recommended services and better options. You should present finance options along with your recommendations.`);
    }else{
        html = "Invite customer to get buying personality.";
    }
    return html;


}

 



}


